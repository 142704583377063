<template>
  <div class="app-conter">
    <div>
      <van-tabs
        sticky
        v-model="active"
        line-width="20PX"
        @click="title"
        title-inactive-color="#000000"
        title-active-color="#A78A48"
        color="#A78A48"
      >
        <van-tab title="基本信息" name="1">
          <div class="main">
            <!-- 基础信息 -->
            <div class="main-basic">
              <div class="title">
                <div class="title-dx"></div>
                <div>基础信息</div>
              </div>
              <div class="main-text">
                <div class="main-text-box">
                  <p>客户经理:</p>
                  <p>{{ customersVo.customerManagerName }}</p>
                </div>
                <div class="main-text-box">
                  <p>借款人姓名:</p>
                  <p>{{ customersVo.customerName }}</p>
                </div>
                <div class="main-text-box">
                  <p>身份证号:</p>
                  <p>{{ customersVo.idCard }}</p>
                </div>
              </div>
            </div>
            <!-- 贷款信息 -->
            <div class="main-basic">
              <div class="title">
                <div class="title-dx"></div>
                <div>贷款信息</div>
              </div>
              <div class="main-text">
                <div class="main-text-box">
                  <p>申请产品:</p>
                  <p v-if="orderVo.productCode == 1">房抵贷</p>
                  <p v-if="orderVo.productCode == 2">赎楼贷</p>
                </div>
                <div class="main-text-box">
                  <p>申请金额(元):</p>
                  <p>{{ orderVo.applyAmount }}</p>
                </div>
                <div class="main-text-box">
                  <p>借款用途:</p>
                  <p>{{ orderVo.moneyUseFor }}</p>
                </div>
                <div class="main-text-box">
                  <p>新增类型:</p>
                  <p v-if="orderVo.newType == 'SCDZ'">首次借款</p>
                  <p v-if="orderVo.newType == 'DZZJ'">贷中增资（新抵押物）</p>
                  <p v-if="orderVo.newType == 'DZZJO'">贷中增资（原抵押物）</p>
                  <p v-if="orderVo.newType == 'JQZD'">结清再贷</p>
                  <p v-if="orderVo.newType == 'SQZQ'">申请展期</p>
                </div>
                <div class="main-text-box">
                  <p>申请借款期数(月):</p>
                  <p>{{ orderVo.applyPeriods }}</p>
                </div>
                <div class="main-text-box">
                  <p>年利率（%）:</p>
                  <p>{{ orderVo.applyAnnualInterestRate }}</p>
                </div>
                <div class="main-text-box">
                  <p>抵押类型:</p>
                  <p>{{ orderVo.mortgageType }}</p>
                </div>
              </div>
            </div>
            <!-- 抵押物信息 -->
            <div class="main-basic">
              <div class="title">
                <div class="title-dx"></div>
                <div>抵押物信息</div>
              </div>
              <div class="main-text">
                <div class="main-text-box">
                  <p>产权人:</p>
                  <p>{{ orderPawnVo.propertyOwner }}</p>
                </div>
                <div class="main-text-box">
                  <p>不动产权证号:</p>
                  <p>{{ orderPawnVo.realEstateNumber }}</p>
                </div>
                <div class="main-text-box">
                  <p>房产坐落:</p>
                  <p>{{ orderPawnVo.houseSiteName }}</p>
                </div>
                <div class="main-text-box">
                  <p>房屋类型:</p>
                  <p>{{ transHouseType(orderPawnVo.houseType) }}</p>
                </div>
                <div class="main-text-box">
                  <p>土地性质:</p>
                  <p>{{ transHouseLandStatus(orderPawnVo.houseLandStatus) }}</p>
                </div>
                <div class="main-text-box">
                  <p>建筑面积（m²）:</p>
                  <p>{{ orderPawnVo.houseScale }}</p>
                </div>
                <div class="main-text-box">
                  <p>土地使用权面积（m²）:</p>
                  <p>{{ orderPawnVo.landUseArea }}</p>
                </div>
                <div class="main-text-box">
                  <p>共有情况:</p>
                  <p>{{ orderPawnVo.inCommonSituation }}</p>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="订单进度" name="2">
          <div class="main">
            <van-steps
              style="margin-bottom: 20px"
              direction="vertical"
              :active="length - 1"
              active-color="#BCA675"
              inactive-color="#F4F0E7"
            >
              <van-step v-for="(item, index) in orderprogress" :key="index">
                <template slot="inactive-icon">
                  <van-icon name="checked" color="#F4F0E7" />
                </template>
                <template slot="finish-icon">
                  <van-icon name="checked" color="#BCA675" />
                </template>
                <template slot="active-icon">
                  <van-icon name="checked" color="#BCA675" />
                </template>
                <div v-if="index <= length - 1">
                  <p style="color: black; margin-bottom: 10px">
                    {{ item.title }}
                  </p>
                  <p class="actp" style="color: #bca675">{{ item.describe }}</p>
                  <p class="actp" v-if="index != 0">结果：{{ item.sonType }}</p>
                  <p class="actp" v-if="index != 0">
                    审批时间：{{ item.time }}
                  </p>
                  <p class="actp" v-else>申请时间：{{ item.time }}</p>
                  <div style="white-space: pre-wrap" class="actp">
                    {{ item.content }}
                  </div>
                </div>
                <div v-else class="Type">
                  <p style="margin-bottom: 10px; font-size: 14px">
                    {{ item.title }}
                  </p>
                  <p>{{ item.describe }}</p>
                  <p>{{ item.result }}</p>
                </div>
              </van-step>
            </van-steps>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { typeInOrderInfo, logs } from "../../api/order/order";
export default {
  data() {
    return {
      active: 1,
      orderNumber: "",
      orderId: "",
      //基础信息数据
      customersVo: {},
      //贷款信息数据
      orderVo: {},
      //抵押物信息数据
      orderPawnVo: {},
      //订单进度
      orderprogress: [
        {
          title: "申请",
          result: "",
          time: "",
          content: "",
        },
        {
          title: "初审",
          result: "",
          time: "",
          content: "",
        },
        {
          title: "下户",
          result: "",
          time: "",
          content: "",
        },
        {
          title: "复审",
          result: "",
          time: "",
          content: "",
        },
        {
          title: "终审",
          result: "",
          time: "",
          content: "",
        },
        {
          title: "面签",
          result: "",
          time: "",
          content: "",
        },
        {
          title: "权证",
          result: "",
          time: "",
          content: "",
        },
        {
          title: "客服",
          result: "",
          time: "",
          content: "",
        },
        {
          title: "总经办",
          result: "",
          time: "",
          content: "",
        },
        {
          title: "放款",
          result: "",
          time: "",
          content: "",
        },
      ],
      // 新订单进度
      avitorder: [],
      length: 0,
    };
  },
  methods: {
    // tab 切换
    title(name) {
      this.active = name;
    },
    // 获取录单详情信息
    gettypeInOrderInfo() {
      typeInOrderInfo({ orderNumber: this.orderNumber }).then((res) => {
        if (res.data.code == 0) {
          this.customersVo = res.data.data.submitTypeInOrderInfoVO.customersVo;
          this.orderVo = res.data.data.submitTypeInOrderInfoVO.orderVo;
          this.orderPawnVo = res.data.data.submitTypeInOrderInfoVO.orderPawnVo;
        }
      });
    },
    // 获取订单进度
    getlogs() {
      logs({ orderId: this.orderId }).then((res) => {
        if (res.data.code == 0) {
          this.avitorder = res.data.data;
          for (let i = 0; i < this.avitorder.length; i++) {
            this.orderprogress[i].describe = this.avitorder[i].type;
            this.orderprogress[i].title = this.avitorder[i].state;
            this.orderprogress[i].result = this.avitorder[i].result;
            this.orderprogress[i].result = this.avitorder[i].sonType;
            this.orderprogress[i].time = this.avitorder[i].updatedAt;
            // 给后台返回的内容更换换行
            if (this.avitorder[i].content) {
              this.orderprogress[i].content = this.avitorder[i].content.replace(
                /\r/g,
                "\n\r"
              );
            }
            if (this.avitorder[i].id) {
              this.length++;
            }

            // 第一个 申请字段 不需要结果信息
            if (i == 0) {
              this.orderprogress[i].content = "";
              this.orderprogress[i].sonType = "";
            }
          }
        }
      });
    },
    // 房屋类型翻译
    transHouseType(type) {
      let trsname = "";
      let list = [
        {
          label: "标准商品房",
          value: "BZSPF",
        },
        {
          label: "房改房",
          value: "FGF",
        },
        {
          label: "产前安置房",
          value: "CQAZF",
        },
        {
          label: "经济适用房",
          value: "JJSYF",
        },
        {
          label: "政策性住房",
          value: "ZCXZF",
        },
        {
          label: "限价商品房",
          value: "XJSPF",
        },
        {
          label: "公寓",
          value: "GY",
        },
        {
          label: "商铺",
          value: "SP",
        },
        {
          label: "办公楼",
          value: "BGL",
        },
        {
          label: "自建房",
          value: "ZJF",
        },
        {
          label: "新工房",
          value: "XGF",
        },
        {
          label: "两限房",
          value: "LXF",
        },
        {
          label: "别墅",
          value: "BS",
        },
        {
          label: "福利房",
          value: "FLF",
        },
        {
          label: "里弄",
          value: "LL",
        },
        {
          label: "保障性住房",
          value: "BZXZF",
        },
        {
          label: "商住两用",
          value: "SZLY",
        },
        {
          label: "其他",
          value: "QT",
        },
      ];
      let dr = list.find((item) => item.value == type);
      if (dr) {
        trsname = dr.label;
      }
      return trsname;
    },
    // 土地性质翻译
    transHouseLandStatus(type) {
      let trsname = "";
      let list = [
        {
          label: "出让",
          value: "CR",
        },
        {
          label: "划拨",
          value: "HB",
        },
        {
          label: "转让",
          value: "ZR",
        },
        {
          label: "其他",
          value: "QT",
        },
      ];
      let dr = list.find((item) => item.value == type);
      if (dr) {
        trsname = dr.label;
      }
      return trsname;
    },
  },
  created() {
    this.orderNumber = this.$route.query.orderNumber;
    this.orderId = this.$route.query.orderId;
    this.gettypeInOrderInfo();
    this.getlogs();
  },
};
</script>

<style scoped>
.app-conter {
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  min-height: 100vh;
}
.warp {
  height: 52px;
  display: flex;
}
.warp-title {
  width: 33%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #f4f7fa;
  color: #af9999;
  border: 1px solid #bbb;
}
.warp-title-active {
  width: 33%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #fff;
  color: #1abc9c;
  border: 1px solid rgb(230, 224, 224);
}
.main {
  width: 90%;
  margin: auto;
  margin-top: 10px;
  overflow: scroll;
}
.main-basic {
  margin-bottom: 20px;
  background: #fff;
  font-size: 14px;
}
.main-text {
  padding: 15px;
}
.main-text-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.title {
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
}
.title-dx {
  width: 5px;
  height: 18px;
  background: linear-gradient(180deg, #bca675 0%, #a78a48 100%);
  margin-right: 15px;
}
.actp {
  color: black;
  font-size: 12px;
}
.Type p {
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
}
</style>
